import React from 'react';
import Footer from '../navigation/Footer';
import MenuBar from '../navigation/Menu';
import { ReactComponent as Bone } from '../images/new/bone.svg';
import { ReactComponent as View } from '../images/new/view.svg';
import { ReactComponent as Doctor } from '../images/new/doctor.svg';
import TestCorner from './../images/new/test_scientific_corner.png';
import { Button } from '../components/ui/button';
import { Link } from 'react-router-dom';
import Badge from '../components/inputs/Badge';
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';

export default function ScientificCorner() {
  const servicesArray = [
    {
      date: 'March 12',
      title: 'The role of Physical Therapy   in Injury Recovery and Prevention',
      doctor: 'Alex bitar',
      type: 'Physitherapy'
    },
    {
      date: 'March 12',
      title: 'The role of Physical Therapy   in Injury Recovery and Prevention',
      doctor: 'Alex bitar',
      type: 'Physitherapy'
    },
    {
      date: 'March 12',
      title: 'The role of Physical Therapy   in Injury Recovery and Prevention',
      doctor: 'Alex bitar',
      type: 'Physitherapy'
    },
    {
      date: 'March 12',
      title: 'The role of Physical Therapy   in Injury Recovery and Prevention',
      doctor: 'Alex bitar',
      type: 'Physitherapy'
    },
    {
      date: 'March 12',
      title: 'The role of Physical Therapy   in Injury Recovery and Prevention',
      doctor: 'Alex bitar',
      type: 'Physitherapy'
    },
    {
      date: 'March 12',
      title: 'The role of Physical Therapy   in Injury Recovery and Prevention',
      doctor: 'Alex bitar',
      type: 'Physitherapy'
    }
  ];
  return (
    <>
      <MenuBar />
      <div
        className="bg-scientificCorner mt-px sm:h-[448px] h-[248px]"
        style={{ backgroundSize: '100% 100%' }}
      >
        <div className="px-10 sm:px-0 max-w-7xl mx-auto w-full h-full flex items-center">
          <div>
            <div className="flex">
              <Bone />
              <div className="B1 font-semibold text-primary">Our Blog</div>
            </div>
            <div className="D1 font-bold text-bold_secondary">Scientific Corner</div>
            <p className="H4 max-w-sm pt-4">
              Get to know more about multiple health topics through the multi-disciplinary approach
              of all our team members.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          boxShadow: '1px 2px 4px 1px #0000000D'
        }}
        className="  h-16 w-full  overflow-y-scroll "
      >
        <div className='flex h-full justify-between items-center max-w-7xl sm:px-0 px-10 mx-auto'>
        <div className=" flex justify-center items-center space-x-4  ">
          <Button className="h-7" variant="outline">
            All
          </Button>
          <Button className="h-7" variant="outline">
            Physiotherapy
          </Button>
          <Button className="h-7" variant="outline">
            Nutrition
          </Button>
          <Button className="h-7" variant="outline">
            General Medicine
          </Button>
          <Button className="h-7" variant="outline">
            Maternity
          </Button>
          <Button className="h-7" variant="outline">
            Other
          </Button>
        </div>
        <div className="B2 text-color_909090 hidden sm:block">Showing 6 articles</div>
      </div>
      </div>
      <div className="max-w-7xl mx-auto px-10 sm:px-0  py-14">
        <div className="grid sm:grid-cols-3 grid-cols-1 gap-4 ">
          {servicesArray.map((service, index) => (
            <div
              style={{ boxShadow: '0px 1px 7px 1px #00000026' }}
              className=" rounded-2xl mx-auto w-full"
              key={index}
            >
              <img src={TestCorner} className="w-full" />
              <div className="p-6">
                <div className="flex justify-between items-center  w-full">
                  <div className="B2 text-color_909090">{service.date}</div>
                  <div>
                    <View className="w-8 h-8" />
                  </div>
                </div>
                <div className="H3 pt-4 font-bold text-color_414141">{service.title}</div>
                <div className="B2 pt-4 text-color_909090">{service.doctor}</div>
                <div className="pt-4">
                  <Badge type={'Physiotherapy'} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center space-x-4">
        <Button size="icon" variant="ghost">
          <ChevronLeft />
        </Button>
        <Button size="icon" variant="ghost">
          1
        </Button>
        <Button size="icon" variant="default">
          2
        </Button>
        <Button size="icon" variant="ghost">
          <ChevronRight />
        </Button>
      </div>
      <div className="grid sm:grid-cols-2 grid-cols-1 sm:py-24 py-10 text-white">
        <div className="sm:ml-auto order-2 sm:order-1 pt-10 sm:pt-0">
          <Doctor className="relative sm:left-5 px-8 sm:px-0" />
        </div>
        <div className="sm:h-[54%] order-1 sm:order-2 sm:w-2/3 mt-auto mb-7 bg-primary py-16 pt-11 flex flex-col justify-center items-center">
          <div className="H1 text-center max-w-xs font-semibold">
            Don’t let your health take a set back!
          </div>
          <div className="H3 font-semibold mt-6">Contact our experts</div>
          <Link to={'/contact-us'} className="border-white mt-6 hover:text-primary hover:bg-white border flex items-center justify-center px-4 py-2 rounded-lg B1 font-semibold ">
            Contact us
            <ArrowRight className="ml-1 w-4 h-5" />
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}
