import React from 'react';
import Logo from '../images/new/logo.svg';

import { ReactComponent as Instagram } from '../images/new/instagram.svg';
import { ReactComponent as Facebook } from '../images/new/facebook.svg';
import { ReactComponent as Tweeter } from '../images/new/tweeter.svg';
import { ReactComponent as Youtube } from '../images/new/youtube.svg';

import { ReactComponent as Email } from '../images/new/email.svg';
import { ReactComponent as Phone } from '../images/new/phone.svg';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    
    <div className="bg-primary ">
      <div className='mx-auto max-w-7xl grid grid-cols-2 md:grid-cols-4  text-white py-8'>
      <div className="mx-auto pb-10 sm:pb-0 col-span-2 sm:col-span-1">
        <div>
          <img src={Logo} />
        </div>
        <div className="flex flex-row space-x-4 pt-6">
          <Link to="#">
            <Facebook className="hover:fill-secondary" />
          </Link>
          <Link to="#">
            <Tweeter className="hover:fill-secondary" />
          </Link>
          <Link to="#">
            <Instagram className="hover:fill-secondary" />
          </Link>
          <Link to="#">
            <Youtube className="hover:fill-secondary" />
          </Link>
        </div>
      </div>
      <div className="mx-auto ">
        <div className="pb-4">
          <div className="H3 font-semibold">Our Services</div>{' '}
          <div className="w-16 bg-secondary h-[2px] mt-2"></div>
        </div>
        <p className="B1">
          Systemic Coaching
          <br />
          Pain Management
          <br />
          Family Medicine
          <br />
          Breast Feeding
          <br />
          Physiotherapy
          <br />
          Biodecoding
          <br />
          Logotherapy
          <br />
          Osteopathy
          <br />
          Nutrition
          <br />
          Fertility{' '}
        </p>
      </div>
      <div className="mx-auto">
        <div className="pb-4">
          <div className="H3 font-semibold">Opening Time</div>
          <div className="w-16 bg-secondary h-[2px] mt-2"></div>
        </div>
        <div className="pb-2 flex">
          <div className="w-10 B1 font-semibold ">Mon</div> 9:00am - 6:00pm
        </div>
        <div className="pb-2 flex">
          <div className="w-10 B1 font-semibold ">Tue</div> 9:00am - 6:00pm
        </div>
        <div className="pb-2 flex">
          <div className="w-10 B1 font-semibold ">Wed</div> 9:00am - 6:00pm
        </div>
        <div className="pb-2 flex">
          <div className="w-10 B1 font-semibold ">Thu</div> 9:00am - 6:00pm
        </div>
        <div className="pb-2 flex">
          <div className="w-10 B1 font-semibold ">Fri</div> 9:00am - 6:00pm
        </div>
      </div>
      <div className="mx-auto pt-10 sm:pt-0">
        <div className="pb-4">
          <div className="H3 font-semibold">Get in Touch</div>
          <div className="w-16 bg-secondary h-[2px] mt-2"></div>
        </div>
        <p className="B1">
          Ground Floor, Mirza
          <br />
          building, facing KMC
          <br />
          hospital, Ghazir,
          <br />
          Kfarehbab bifurcation
        </p>
        <div className=" py-4 B2 hidden sm:flex">
          <Email className="mr-4" />
          info@motionemotion.clinic
        </div>
        <div className="  B2 hidden sm:flex">
          <Phone className="mr-4" />
          +961 70 216 356
        </div>
      </div>
      <div className='mx-auto block pt-10 sm:pt-0 sm:hidden'>
      <div className=" py-4 B2 flex">
          <Email className="mr-4" />
          <div className='text-xs'>
          info@motionemotion.clinic
          </div>
        </div>
        <div className="  B2 flex ">
          <Phone className="mr-4" />
          <div className='text-xs'>
          +961 70 216 356
          </div>
        </div>
      </div>
    </div></div>

  );
}
