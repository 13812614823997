import React from 'react';
import Footer from '../navigation/Footer';
import MenuBar from '../navigation/Menu';
import { ReactComponent as Bone } from '../images/new/bone.svg';
import { ReactComponent as Phone } from '../images/new/phone.svg';
import { ReactComponent as Location } from '../images/new/location.svg';
import { ReactComponent as Email } from '../images/new/email.svg';

import { ReactComponent as Career } from '../images/new/career.svg';

import TestService from './../images/new/test_service.png';
import ContactForm from '../components/ContactForm';

export default function Contact() {
  return (
    <>
      <MenuBar />
      <div className="bg-contactUs mt-px h-[448px]" style={{ backgroundSize: '100% 100%' }}></div>
      <div className="px-10 sm:px-0">
        <div
          className="max-w-3xl relative bottom-12 mx-auto bg-white rounded-lg"
          style={{
            boxShadow: '1px 2px 4px 1px #00000026'
          }}
        >
          <div className="sm:px-24 sm:py-14 p-10">
            <div>
              <div className="flex">
                <Bone />
                <div className="B1 font-semibold text-primary">NEED ANY HELP?</div>
              </div>
              <div className="H1 font-semibold pt-2 text-bold_secondary">Get in touch with us</div>
          <div className='pt-8'>
            <ContactForm />
            </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto sm:px-0 px-10 ">
        <div className="H1 font-semibold text-bold_secondary">Find us Here</div>
        <div className="grid sm:grid-cols-3 grid-cols-1 py-8">

          <div className="flex py-2 sm:py-0 sm:pl-12 items-center">
            <div className=" bg-[#ECECEC] p-3 rounded-lg mr-5">
              <Phone className='w-6 h-6' />
            </div>
            <div>
              <div className="B1 text-color_909090">Any Questions? Call us</div>
              <div className="H3 font-semibold text-primary">+961 70 216 356</div>
            </div>
          </div>

          <div className="flex py-2 sm:py-0 sm:pl-12 items-center  border-color-[#DDD8DB]  border border-y-0 sm:border-x-2 border-x-0  ">
            <div className=" bg-[#ECECEC] p-3 rounded-lg mr-5">
              <Email className='w-6 h-6' />
            </div>
            <div>
              <div className="B1 text-color_909090">Any Questions? Email us</div>
              <div className="H3 font-semibold text-primary">info@motionemotion.clinic</div>
            </div>
          </div>

          <div className="flex py-2 sm:py-0 sm:pl-12 sm:items-center   items-start">
            <div className="bg-[#ECECEC] p-3 rounded-lg mr-5">
              <Location className='w-6 h-6' />
            </div>
            <div>
              <div className="B1 text-color_909090">Ghazir, Kfarebab bifurcation</div>
              <div className="H3 font-semibold text-primary">
                GF, Mirza Bld, Facing KMC hospital
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="bg-maps h-[448px]" style={{ backgroundSize: '100% 100%' }}></div>

      <div className="grid px-10 sm:px-0 sm:grid-cols-2 grid-cols-1 sm:py-24 py-10 text-white max-w-3xl mx-auto ">
        <div className="mx-auto sm:mx-0 order-2 sm:order-1 pt-10 sm:pt-0">
          <Career className="px-8 sm:px-0" />
        </div>

        <div className="order-1 m-auto  sm:order-2 ">

          <div className="flex">
            <Bone />
            <div className="B1 font-semibold text-primary">CAREER</div>
          </div>
          <div className="H2 font-bold mt-2 text-bold_secondary">
            Are you looking to join our team?
          </div>
          <div className="mt-8">
            <div className="flex ">
              <div className=" bg-[#ECECEC] p-3 rounded-lg mr-5">
                <Email />
              </div>
              <div>
                <div className="B1 text-color_909090">Send us your resume on</div>
                <div className="H3 font-semibold text-primary">career@motionemotion.clinic</div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
