import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import { toastError, toastLoading, toastSucess } from '../../utils/toaster';
import { db } from '../../utils/firebase';
import { Button } from './../ui/button';

import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';


export default function Announcement() {
  const navigate = useNavigate();

  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleDelete = async id => {
    

    try {
      toastLoading();
      const announcementRef = doc(db, 'announcements', id);
      await deleteDoc(announcementRef);
      // Update the announcements state by filtering out the deleted announcement
      setAnnouncements(prevAnnouncements =>
        prevAnnouncements.filter(announcement => announcement.id !== id)
      );
      toast.dismiss();
      toastError('successfully deleted!');
    } catch (error) {
      toast.dismiss();
      toastSucess('Something went wrong!');
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      
    const announcementsRef = collection(db, 'announcements');
    const querySnapshot = await getDocs(announcementsRef);

    const announcementsList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setAnnouncements(announcementsList);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
  };

  return (
    <div className=" h-screen main-screen-width p-5">
      <div className="flex justify-end">
        <Button onClick={()=>navigate('/admin/announcement/add')} className=" hover:bg-primary text-primary border-primary" variant="outline">
          Add
        </Button>
      </div>
      <div>
       {loading?<div className='w-full text-center'>Loading...</div>: <table className="w-full mt-10 text-center border border-black">
          <thead>
            <tr className="border border-black">
              <th className='w-1/5'>Doctor</th>
              <th className='w-1/5'>Date</th>
              <th className='w-1/5'>Title</th>
              <th className='w-1/5'>Type</th>
              <th className='w-1/5'>Action</th>
            </tr>
          </thead>

          <tbody>
            {announcements.map((item, index) => (
              <tr className="border border-black" key={index}>
                <td className="p-2">{item.doctor}</td>
                <td>{item.date}</td>
                <td>{item.title}</td>
                <td>{item.type}</td>
                <td>
                  <Button
                    onClick={() => handleDelete(item.id)}
                    className="h-7 hover:bg-primary text-primary border-primary"
                    variant="outline"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>}
      </div>
    </div>
  );
}
