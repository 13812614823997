import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import NavLogo from '../images/new/navLogo.svg';
import Logo from '../images/new/logo.svg';
import { Menu } from 'lucide-react';

export default function MenuBar() {
    const [open, setOpen] = useState(false)

    const location = useLocation();
    const path = location.pathname;

    const routes=[{
        path:'about-us',
        name:'About us'

    },{
        path:'services',
        name:'Services'
        
    },{
        path:'scientific-corner',
        name:'Scientific Corner'
        
    },{
        path:'announcements',
        name:'Announcements'
        
    }]
    return (<>
        <div className='bg-primary sm:bg-white    py-7' style={{
            'boxShadow': '0px 1px 4px 0px #00000040'

        }}>
            <div className='max-w-7xl sm:px-0 px-10 flex justify-between items-center mx-auto'>
            <Link className='sm:block hidden' to="/">
                <img src={NavLogo} />
            </Link>
            <div className='sm:flex hidden items-center space-x-4'>
                {routes.map(route=>
                    <Link key={route.path} to={`/${route.path}`} className={`B1 hover:text-bold_secondary ${path === `/${route.path}` ? 'text-bold_secondary' : ''}`}>{route.name}</Link>
                    )}
                
                <Link to="/contact-us" className={`B1 bg-bold_secondary  hover:text-black  px-5 py-3 rounded-md ${path === '/contact-us' ? 'text-black' : 'text-white'}`}>Contact us</Link> 
            </div>
            {/* MOBILE */}
            <Link className='block sm:hidden' to="/">
            <img src={Logo} className='w-12 ' />
            </Link>
            {/* <img src={Logo} className='w-12 block sm:hidden' /> */}

            <Menu onClick={()=>setOpen(prev=>!prev)}  className=' w-12 h-12 block sm:hidden  text-white' />


            </div>
            
        </div>
        {open && <div className=' z-50 absolute w-full' style={{
              background: 'linear-gradient(to left, rgba(0, 0, 0, 0.3), #2F5AAA)'

        }}>
        {routes.map(route=>
                   <div key={route.path} className='p-3'><Link  to={`/${route.path}`} className={`B1  ${path === `/${route.path}` ? 'text-bold_secondary' : 'text-white '}`}>{route.name}</Link></div>
                    )}
                    <div  className='p-3'><Link  to={`/contact-us`} className={`B1  ${path === `/contact-us` ? 'text-bold_secondary' : 'text-white '}`}>Contact us</Link></div>
        </div>}
        </>
    )
}
