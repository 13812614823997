import React, { useEffect, useState } from 'react';

import { ReactComponent as ArrowDown } from '../images/new/arrowDown.svg';


import { useTranslation } from 'react-i18next';

const Accordion = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(-1);
  const questions =
    [
      {name:'What makes Motion Emotion different from other healthcare providers?',reply:''},
      {name:'How long is a typical appointment?',reply:'Appointment duration varies based on the nature of the service. Physiotherapy and coaching sessions typically last up to 90 minutes, while medical consultations may vary. Our team will provide you with specific details when scheduling your appointment.'},
      {name:'Is Motion Emotion suitable for all ages?',reply:''},
      {name:"How can I stay updated on Motion Emotion's events and health tips?",reply:''}
    ];

 

  const toggleAccordion = index => {
    setIsOpen(prev => (index == prev ? -1 : index));
  };

  return (
    <div className="w-full">
      <div>
        {questions.map((question, index) => (
          <div
            key={index}
            className="mb-6 bg-white border rounded-lg p-6  flex flex-col  "
          >
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleAccordion(index)}
            >
              <div className="font-bold H4 text-bold_secondary">{question.name}</div>
              <div>
                <ArrowDown className={`transition-all ${isOpen ===index ? 'rotate-180':''}`} />
              </div>
            </div>
            {isOpen == index && <div className="pt-3.5 B3">{question.reply}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
