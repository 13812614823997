import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyB5-5klCS2Mj8UUMiCdtB89D3Z-dhkECQk",
  authDomain: "motion-emontion.firebaseapp.com",
  projectId: "motion-emontion",
  storageBucket: "motion-emontion.appspot.com",
  messagingSenderId: "440012593258",
  appId: "1:440012593258:web:4ad245547f0766385ad213",
  measurementId: "G-KCKCQ72FZV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);


export { auth, db };
