import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  lng: 'en', // Set the default language
  fallbackLng: 'en', // Fallback language if translation is not available in the selected language
  resources: {
    en: {
      translation: {
        chat: 'Chat',
        categories: 'Categories',
        roles: 'Roles',
        history: 'History',
        faq: 'FAQ',
        terms_and_conditions: 'Terms & Conditions',
        english: 'English',
        light_mode: 'Light Mode',
        welcome_to_arab_gpt: 'Welcome to ArabGPT',
        welcome_text:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has Lorem Ipsum is simply dummy text of the printing and typesetting',
        logout: 'Logout',
        login: 'Login',
        send_message: 'Send Message...',
        coming_soon: 'Coming Soon',
        search: 'Search',
        discover_our_categories: 'Discover our Categories',

        personal_finance: 'Personal Finance',
        food_and_cooking: 'Food and Cooking',
        health: 'Health',
        investment_and_trading: 'Investment and Trading',
        sports: 'Sports',
        nutrition: 'Nutrition',
        movies_and_series: 'Movies and Series',
        education: 'Education',
        science: 'Science',
        music: 'Music',
        development: 'Development',
        quran: 'Quran',
        psychology: 'Psychology',
        translation: 'Translation',
        life_coaching: 'Life Coaching',
        social_media: 'Social Media',
        marketing: 'Marketing',
        trave: 'Travel',

        stop_generating: 'STOP GENERATING',
        new_chat: 'New Chat',

        personal_finance_text:
          "Hello! I'm your AI personal finance guide. How can I assist you with your finances?",
        food_and_cooking_text: 'Hello! I am your AI chef assistant. How can I help you?',
        health_text: "Greetings! I'm your AI Doctor. What can I do for you today?",
        investment_and_trading_text:
          "Greetings! I'm your AI trading guide. How can I assist you with your investments?",
        sports_text: "Hello! I'm your AI Personal trainer. Which sport would you like to discuss?",
        nutrition_text:
          "Greetings! I'm your AI Dietician. How can I guide you with your diet today?",
        movies_and_series_text:
          "Hello! I'm your AI film buff. What movie or series are you thinking about?",
        education_text:
          "Greetings! I'm your AI educational guide. How can I support your learning journey?",
        science_text:
          "Hello! I'm your AI science expert. What scientific topic sparks your curiosity?",
        music_text:
          "Greetings! I'm your AI music maestro. What tune or genre are you in the mood for?",
        development_text:
          "Hello! I'm your AI coding assistant. How can I help with your programming needs?",
        quran_text:
          "Greetings! I'm your AI Quran guide. Which Surah or Ayah would you like to explore?",
        psychology_text:
          "Hello! I'm your AI psychology consultant. How can I assist with your mental well-being?",
        translation_text:
          "Greetings! I'm your AI translator. Which language would you like to translate?",
        life_coaching_text:
          "Hello! I'm your AI life coach. How can I guide you towards your goals?",
        social_media_text:
          "Greetings! I'm your AI social media strategist. How can I boost your online presence?",
        marketing_text:
          "Greetings! I'm your AI social media strategist. How can I boost your online presence?",
        travel_text: "Hello! I'm your AI touristic guide. Where would you like to go today?",

        q1: 'What is ArabGPT?',
        q2: 'How does ArabGPT work?',
        q3: 'Is ArabGPT a free service?',
        q4: 'How do I create an account?',
        q5: 'What personal information do I need to provide during the registration process?',
        q6: 'Is my data secure with ArabGPT?',
        q7: 'Can I get support for issues or questions?',
        q8: 'How accurate are the responses from ArabGPT?',

        reply1:
          'ArabGPT is a cutting-edge platform that offers interactive engagement across various categories, including culinary advice, psychological counseling, and more. It is a platform developed by ToAI Company.',
        reply2:
          'ArabGPT allows you to engage in dynamic conversations and receive helpful responses across different subjects.',
        reply3:
          'Yes, currently ArabGPT is entirely free to use. However, please note that in the future, we may introduce paid services for additional features.',
        reply4:
          "To create an account, simply click on the 'Sign Up' button on our homepage and provide the required information. You'll receive a confirmation email to activate your account.",
        reply5:
          'During registration, we will ask for your email address, and create a password. Or Connect it with your social media account.',
        reply6:
          'Yes, we take data security seriously. Your information is protected through a combination of organizational and technical measures. For more details, please refer to our Privacy Policy.',
        reply7:
          'Certainly! If you have any questions, concerns, or need assistance, feel free to reach out to us via email at contact@arabgpt.ai. You can also contact us through our provided mobile number [Your Mobile Number]. We are here to help!',
        reply8:
          'While we strive to provide useful and accurate information, please remember that ArabGPT is a sophisticated system designed to assist users. However, it is not a substitute for professional advice, especially in areas related to health, safety, legal, or financial matters.',
        privacy_policy: 'Privacy Policy',

        pp1: 'Introduction',
        pp1t: 'Welcome to arabgpt! \n We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at Contact@arabgpt.ai.',
        pp2: 'Information We Collect',
        pp2t: 'We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and services.',
        pp3: 'How We Use Your Information',
        pp3t: 'We use the information we collect or receive: \n -To facilitate account creation and logon process. \n -To send administrative information to you.',
        pp4: 'Disclosure of Your Information',
        pp4t: 'We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.',
        pp5: 'Data Security',
        pp5t: 'We aim to protect your personal information through a system of organizational and technical security measures.',
        pp6: 'Your Privacy Rights',
        pp6t: 'You have certain rights under applicable data protection laws. These may include the right to request access and obtain a copy of your personal information, request rectification or erasure, restrict the processing of your personal information, and if applicable, to data portability.',
        pp7: 'Data Retention',
        pp7t: 'We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law.',
        pp8: 'Updates to this policy',
        pp8t: 'We may update this privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible.',
        pp9: 'How to Contact Us',
        pp9t: 'If you have questions or comments about this policy, email us at Contact@arabgpt.ai.',

        t1: 'Introduction',
        t1t: "Welcome to arabgpt! \n These Terms and Conditions outline the rules and regulations for the use of arabgpt's website. By accessing this website, we assume you accept these terms and conditions. Do not continue to use arabgpt if you do not agree to all of the terms and conditions stated on this page.",
        t2: 'Acceptance of Terms',
        t2t: 'By using our service, you are agreeing to these terms. If you do not agree, please do not use the service.These terms may be revised as needed, so please review them frequently.',
        t3: 'Changes to the Terms',
        t3t: 'We reserve the right, at our sole discretion, to amend these Terms and Conditions at any time. We will notify you of any changes by posting the new Terms and Conditions on this page.',
        t4: 'Privacy Policy and Personal Information',
        t4t: 'By using our services, you agree to the collection and use of information in accordance with our Privacy Policy.',
        t5: 'User Responsibilities and Obligations',
        t5t: 'Users of arabgpt are not permitted to use the platform to distribute malicious content or engage in any activities that violate any applicable laws or regulations. You are responsible for any content you create or share through the service.',
        t6: 'Intellectual Property Rights',
        t6t: 'Other than the content you own, under these Terms, arabgpt and/or its licensors own all the intellectual property rights and materials contained in this website.',
        t7: 'Limitations of Liability',
        t7t: 'Arabgpt and its directors, employees, partners, agents, suppliers, or affiliates, shall not be liable for any indirect, incidental, special, consequential or punitive damages, including loss of profits, data, use, goodwill, or other intangible losses.',
        t8: 'Termination of Accounts',
        t8t: 'We may terminate or suspend your access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.',
        t9: 'Contact Information',
        t9t: 'If you have any questions about these Terms, please contact us at Contact@arabgpt.ai.'
      }
    },
    ar: {
      translation: {
        chat: 'دردشة',
        categories: 'فئات',
        roles: 'Roles',
        history: 'تاريخ',
        faq: 'الأسئلة المتكررة',
        terms_and_conditions: 'شروط وأحكام',
        english: 'إنجليزي',
        light_mode: 'الموضوع الفاتح',
        welcome_to_arab_gpt: 'مرحبًا',
        welcome_text:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has Lorem Ipsum is simply dummy text of the printing and typesetting',
        logout: 'تسجيل خروج',
        login: 'تسجيل الدخول',
        send_message: 'إرسال رسالة ...',
        coming_soon: 'Coming Soon',
        search: 'بحث',
        discover_our_categories: 'اكتشف فئاتنا',

        personal_finance: 'التمويل الشخصي',
        food_and_cooking: 'الطعام والطبخ',
        health: 'صحة',
        investment_and_trading: 'الاستثمار والتداول',
        sports: 'رياضة',
        nutrition: 'تغذية',
        movies_and_series: 'أفلام ومسلسلات',
        education: 'تعليم',
        science: 'علوم',
        music: 'موسيقى',
        development: 'التطوير والبرمجة',
        quran: 'القرآن',
        psychology: 'علم النفس',
        translation: 'ترجمة',
        life_coaching: 'التوجيه الحياتي',
        social_media: 'وسائل التواصل الاجتماعي',
        marketing: 'التسويق',
        travel: 'سفر',

        stop_generating: 'توقف',
        new_chat: 'دردشة جديدة',

        personal_finance_text: 'مرحبًا! أنا مساعدك المالي الذكي. كيف يمكنني مساعدتك في ماليتك؟',
        food_and_cooking_text: 'مرحبًا! أنا مساعدك الطهي الذكي. كيف يمكنني مساعدتك؟',
        health_text: 'تحية طيبة! أنا طبيبك الذكي. كيف يمكنني مساعدتك اليوم؟',
        investment_and_trading_text:
          'تحية طيبة! أنا مرشدك الذكي في التداول والاستثمار. كيف يمكنني مساعدتك؟',
        sports_text: 'مرحبًا! مدربك الشخصي الرياضي الذكي. أي رياضة ترغب في مناقشتها؟',
        nutrition_text:
          'تحية طيبة! أنا أخصائي التغذية الذكي. كيف يمكنني مساعدتك في نظامك الغذائي اليوم؟',
        movies_and_series_text:
          'مرحبًا! أنا خبير الأفلام الذكي. عن أي فيلم أو مسلسل ترغب في الحديث؟',
        education_text: 'تحية طيبة! أنا مرشدك التعليمي الذكي. كيف يمكنني دعم رحلتك التعليمية؟',
        science_text: 'مرحبًا! أنا خبيرك العلمي الذكي. أي موضوع علمي يثير فضولك؟',
        music_text: 'تحية طيبة! أنا معلمك الموسيقي الذكي. أي نوع موسيقي تود الاستماع إليه؟',
        development_text:
          'مرحبًا! أنا مساعدك البرمجي الذكي. كيف يمكنني مساعدتك في احتياجاتك البرمجية؟',
        quran_text: 'تحية طيبة! أنا مرشدك الذكي للقرآن الكريم. أي سورة أو آية تود استكشافها؟',
        psychology_text: 'مرحبًا! أنا مستشارك النفسي الذكي. كيف يمكنني مساعدتك في رفاهيتك النفسية؟',
        translation_text: 'تحية طيبة! أنا مترجمك الذكي. أي لغة ترغب في ترجمتها؟',
        life_coaching_text: 'مرحبًا! أنا مدرب حياتك الذكي. كيف يمكنني مساعدتك نحو أهدافك؟',
        social_media_text:
          'تحية طيبة! أنا استراتيجي التسويق الاجتماعي الذكي. كيف يمكنني تعزيز وجودك على الإنترنت؟',
        marketing_text:
          'تحية طيبة! أنا استراتيجي التسويق الاجتماعي الذكي. كيف يمكنني تعزيز وجودك على الإنترنت؟',
        travel_text: 'مرحبًا! أنا مرشدك السياحي الذكي. أين تود الذهاب اليوم؟',

        q1: 'ما هوArabGPT ؟',
        q2: 'كيف يعمل ArabGPT ؟',
        q3: 'هل  ArabGPT خدمة مجانية؟',
        q4: 'كيف يمكنني إنشاء حساب؟',
        q5: 'ما هي المعلومات الشخصية التي يجب علي تقديمها أثناء عملية التسجيل؟',
        q6: '  هل بياناتي آمنة مع ArabGPT ؟',
        q7: 'هل يمكنني الحصول على دعم للمشكلات أو الأسئلة؟',
        q8: ' مدى دقة الردود منArabGPT ؟',

        reply1:
          'هو منصة متطورة تقدم مشاركة تفاعلية عبر فئات متنوعة، بما في ذلك النصائح الطهي، والإرشاد النفسي، وغيرها. إنها منصة تم تطويرها بواسطة شركة To-AI.',
        reply2:
          'يمكنك استخدام ArabGPT للمشاركة في محادثات ديناميكية والحصول على ردود مفيدة في مواضيع مختلفة',
        reply3:
          'نعم، حاليًا ArabGPT مجاني بالكامل للاستخدام. ومع ذلك، يرجى ملاحظة أنه في المستقبل قد نقدم خدمات مدفوعة لمزايا إضافية',
        reply4:
          'لإنشاء حساب، ما عليك سوى النقر على زر "التسجيل" على صفحتنا الرئيسية وتقديم المعلومات المطلوبة. ستتلقى رسالة تأكيد عبر البريد الإلكتروني لتفعيل حسابك',
        reply5:
          'أثناء عملية التسجيل، سنطلب منك عنوان بريدك الإلكتروني وإنشاء كلمة مرور. أو ربطه بحسابك على وسائل التواصل الاجتماعي',
        reply6:
          'نعم، نحن نأخذ أمان البيانات على محمل الجد. تتم حماية معلوماتك من خلال مزيج من الإجراءات التنظيمية والتقنية. لمزيد من التفاصيل، يرجى الرجوع إلى سياسة الخصوصية لدينا',
        reply7:
          'يمكنك أيضًا الاتصال بنا عبر رقم الجوال المقدم [رقم جوالك].  contact@arabgpt.ai بالتأكيد نحن هنا للمساعدة! إذا كان لديك أي أسئلة، أو مخاوف، أو تحتاج إلى مساعدة، فلا تتردد في التواصل معنا عبر البريد الإلكتروني على',
        reply8:
          'بينما نسعى لتقديم معلومات مفيدة ودقيقة، يرجى تذكر أنه نظام متطور مصمم لمساعدة المستخدمين. ومع ذلك، ليس بديلاً عن النصائح المهنية، خاصة في المجالات المتعلقة بالصحة والسلامة والقانون والشؤون المالية          ',
        privacy_policy: 'سياسة الخصوصية',



        pp1: 'مقدمة',
        pp1t: 'مرحبًا بك في ! ArabGPT \n نحن ملتزمون بحماية معلوماتك الشخصية وحقك في الخصوصية. إذا كان لديك أي استفسارات أو مخاوف بشأن سياستنا، أو الإجراءات التي نتبعها فيما يتعلق بمعلوماتك الشخصية، فنرجو منك التواصل معنا على Contact@arabgpt.ai.',
        pp2: 'المعلومات التي نقوم بجمعها',
        pp2t: 'نقوم بجمع المعلومات الشخصية التي تقدمها لنا طوعيًا عندما تعبر عن اهتمامك بالحصول على معلومات حولنا أو منتجاتنا وخدماتنا.',
        pp3: 'كيفية استخدام معلوماتك',
        pp3t: 'نستخدم المعلومات التي نجمعها أو نتلقاها:  \n لتسهيل عملية إنشاء الحساب وتسجيل الدخول . \n لإرسال معلومات إدارية إليك.',
        pp4: 'الكشف عن معلوماتك',
        pp4t: 'نقوم فقط بمشاركة المعلومات بموافقتك، للامتثال للقوانين، لحماية حقوقك، أو لتنفيذ التزامات الأعمال.',
        pp5: 'أمان البيانات',
        pp5t: 'نهدف إلى حماية معلوماتك الشخصية من خلال نظام من التدابير التنظيمية والتقنية الأمانية.',
        pp6: 'حقوق الخصوصية الخاصة بك',
        pp6t: 'لديك حقوق معينة بموجب قوانين حماية البيانات السارية. قد تشمل هذه الحقوق الحق في طلب الوصول والحصول على نسخة من . معلوماتك الشخصية، وطلب تصحيح أو حذف، وتقييد معالجة معلوماتك الشخصية، وإذا كان ذلك مناسبًا، حق نقل البيانات.',
        pp7: 'الاحتفاظ بالبيانات',
        pp7t: 'سنحتفظ فقط بمعلوماتك الشخصية لأطول فترة ممكنة ضرورية لأغراض محددة في سياسة الخصوصية هذه، ما لم يتطلب القانون . فترة احتفاظ أطول أو تسمح بذلك.',
        pp8: 'تحديثات لهذه السياسة',
        pp8t: 'قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سيتم الإشارة إلى الإصدار المحدث من خلال تاريخ "تم تنقيحه". المحدث، وسيكون الإصدار المحدث فعّالًا فور الوصول إليه.',
        pp9: 'كيفية التواصل معنا',
        pp9t: 'إذا كان لديك أسئلة أو تعليقات حول هذه السياسة، يمكنك مراسلتنا عبر البريد الإلكتروني على Contact@arabgpt.ai.',

        t1: 'مقدمة',
        t1t: 'مرحبا بكم في ArabGPT \n  إذا كنت لا توافق على جميع الشروط والأحكام المذكورة في هذه الصفحة ArabGPT توضح هذه الشروط والأحكام القواعد واللوائح الخاصة باستخدام موقع من خلال دخولك إلى هذا الموقع  ،   نفترض  أنك تقبل هذه الشروط والأحكام. لا تستمر في استخدام. ',
        t2: 'قبول الشروط',
        t2t: 'باستخدام خدمتنا، فإنك توافق على هذه الشروط. إذا كنت لا توافق، يرجى عدم استخدام الخدمة. يمكن مراجعة هذه الشروط .حسب الحاجة، لذا يرجى مراجعتها بشكل متكرر.',
        t3: 'التغييرات في الشروط',
        t3t: 'نحن نحتفظ بالحق، وفقًا لتقديرنا الخاص، في تعديل هذه الشروط والأحكام في أي وقت. وسنقوم بإعلامك بأي تغييرات عن طريق .نشر الشروط والأحكام الجديدة على هذه الصفحة.',
        t4: 'سياسة الخصوصية والمعلومات الشخصية',
        t4t: 'باستخدام خدماتنا، فإنك توافق على جمع واستخدام المعلومات وفقًا لسياسة الخصوصية الخاصة بنا.',
        t5: 'مسؤوليات والتزامات المستخدم',
        t5t: 'لا يُسمح لمستخدمي ArabGPT \n باستخدام النظام الأساسي لتوزيع محتوى ضار أو المشاركة في أي أنشطة تنتهك أي قوانين أو لوائح معمول بها .أنت مسؤول عن أي محتوى تقوم بإنشائه أو مشاركته من خلال الخدمة',
        t6: 'حقوق الملكية الفكرية',
        t6t: 'وبموجب هذه الشروط، تمتلك شركة و/أو الجهات المرخصة لها جميع حقوق الملكية الفكرية والمواد الواردة في هذا الموقع ArabGPT بخلاف المحتوى الذي تملكه.',
        t7: ' حدود المسؤولية',
        t7t: 'لن تتحملArabGPT  ومديريها أو موظفيها أو شركائها أو وكلائها أو مورديها أو الشركات التابعة لها المسؤولية عن أي أضرار غير مباشرة أو عرضية أو خاصة أو تبعية أو تأديبية، بما في ذلك خسارة الأرباح أو البيانات أو الاستخدام أو الشهرة أو غيرها من الخسائر غير الملموسة.',
        t8: 'إنهاء الحسابات',
        t8t: 'يجوز لنا إنهاء أو تعليق وصولك إلى الخدمة على الفور، دون إشعار مسبق أو مسؤولية، وفقًا لتقديرنا الخاص، لأي سبب كان ودون .حصر، بما في ذلك على سبيل المثال لا الحصر، خرق الشروط.',
        t9: 'معلومات الاتصال',
        t9t: 'إذا كان لديك أسئلة أو تعليقات حول هذه السياسة، يمكنك مراسلتنا عبر البريد الإلكتروني علىContact@arabgpt.ai '
      }
    }
    // Add translations for other languages here
  },
  interpolation: {
    escapeValue: false // Allows interpolation in translation strings
  }
});

export default i18n;
