import React from 'react';

// eslint-disable-next-line react/prop-types
export default function Button({ placeholder, onClick }) {
  return (
    <div>
      <button
        className="px-16 py-3 font-bold text-base text-white rounded-3xl button-grandient hover:opacity-80"
        onClick={onClick}
      >
        {placeholder}
      </button>
    </div>
  );
}
