import React,{ useState } from 'react';

import { Button } from '../../components/ui/button';
import { db } from '../../utils/firebase';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';

const AddAnnouncement = () => {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    doctor: '',
    date: '',
    type: ''
  });

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const submit = () => {
    //check if all fields are filled
    if (Object.values(formData).some(v => v.trim() === '')) {
      setIsError(true);

      return;
    }
    setIsError(false);

    sendToFirebase(formData);
  };


  const sendToFirebase = async data => {
    try {
      setLoading(true);
      setIsError(false);
      setIsSuccess(false);

      const announcementsRef = collection(db, 'announcements');

          const docRef = await addDoc(announcementsRef, {
              title: data.title,
              doctor: data.doctor,
              date: data.date,
              type: data.type

            });
            navigate('/admin/announcement');
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
      setLoading(false);
    }
  };

  return (
    <div className=" h-screen main-screen-width  p-5">
    <div className=" flex justify-center space-x-4 w-full  p-5">
      <div className='w-1/3'>
        <div className="B1 font-semibold pb-1">Title</div>
        <input
          onChange={handleChange}
          placeholder="Insert Title"
          name="title"
          value={formData.title}
          className="p-3 border rounded-lg w-full focus:outline-none"
        />
      </div>
      <div className='w-1/3'>
        <div className="B1 font-semibold pb-1">Doctor</div>
        <input
          placeholder="Insert Doctor"
          name="doctor"
          value={formData.doctor}

          onChange={handleChange}
          className="p-3 border rounded-lg w-full focus:outline-none"
        />
      </div>
      </div>  
      <div className=" flex justify-center space-x-4 w-full  p-5">
      <div className='w-1/3'>
        <div className="B1 font-semibold pb-1">Type</div>
        <select
          onChange={handleChange}
          name="type"
          value={formData.type}
          className=" p-3 rounded-lg border w-full focus:outline-none"
        >
          <option value="">Select Type</option>
          <option value="Workshops & Events">Workshops & Events</option>
          <option value="Visiting Consultants">Visiting Consultants</option>
          <option value="Special Schedules">Special Schedules</option>
          <option value="Collaboration">Collaboration</option>
          <option value="Recruitment Vacancies">Recruitment Vacancies</option>
        </select>
      </div>
      <div className='w-1/3'>
        <div className="B1 font-semibold pb-1">Date</div>
        <input
          onChange={handleChange}
          placeholder="Insert Date"
          name="date"
          type={'date'}
          value={formData.date}
          className="p-3 border rounded-lg w-full focus:outline-none"
        />
      </div>
      
      </div>
      <div className=" flex justify-end space-x-4 w-full  p-5">
      <div className='w-1/3'></div>
      <div className='w-1/3'>
      <div className="  w-full space-x-4">
        <Button className='bg-red-500 hover:bg-red-400' onClick={()=>navigate('/admin/announcement')} >
          Cancel
        </Button>
        <Button onClick={submit} disabled={loading}>
          {loading ? 'Loading ...' : 'Submit'}

        </Button>
      </div>
      {isError && <div className="text-xs text-red-500">All fields are required!</div>}
      {isSuccess && <div className="text-xs text-red-500">Successfully sent!</div>}
      </div></div>
    </div>
  );
};

export default AddAnnouncement;
