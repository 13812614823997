import React, {  useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Chat } from '../../images/chat.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as SideLogo } from '../../images/side_logo_white.svg';
import {  useNavigate } from 'react-router-dom';
import {  signOut } from "firebase/auth";
import {auth} from '../../utils/firebase';
import { onAuthStateChanged } from "firebase/auth";
 
function Sidebar() {
  const navigate = useNavigate();

 
  const logout = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
      navigate('/login'); 
      }).catch((error) => {
      // An error happened.
      });
  
  };

  const location = useLocation();

  const pages = [
    { name: 'Announcement', img: Chat, link: '/admin/announcement' },
  ];

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
      } else {
        // User is signed out
        navigate('/login'); 
      }
    });
  }, []);

  return (
    <div className="bg-black w-60 lg:w-72 h-screen relative">
      <div className="flex flex-col">
        <div className="mx-auto my-4">
          <Link to={'/chat'}>
            {/* <SideLogo className="lg:w-24 w-20 mt-10 lg:mt-5" /> */}
          </Link>
        </div>

        {pages.map((page, index) => {
          let Img = page.img;
          return (
            <div key={index} className="text-white  text-base font-normal   w-full  my-2">
              <Link to={page.link}>
                <div
                  className={`${location?.pathname === page.link ? 'bg-white-15' : ''} ${
                    location?.pathname === '/category' && page.link == '/Categories'
                      ? 'bg-white-15'
                      : ''
                  } flex  mx-6 space-x-3 rtl:space-x-reverse cursor-pointer py-3.5 rounded-xl  hover:bg-white-15 px-3`}
                >
                  {page.img ? <Img /> : <div className="h-6 w-6"></div>}
                  <div>{page.name}</div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
      <div className="absolute bottom-0 w-full">
        <div className="w-full  my-2 text-white">
               <div onClick={logout} className={`text-center  mx-6 cursor-pointer py-3.5 rounded-xl  hover:bg-white-15 px-3`}>
                Logout
                </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
