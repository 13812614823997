import React from 'react';

// eslint-disable-next-line react/prop-types
export default function Input({ placeholder, name, type, onChange }) {
  return (
    <div>
      <input
        name={name}
        type={type ?? 'text'}
        onChange={onChange}
        placeholder={placeholder}
        className="text-base pl-5 font-normal bg-color_EFEFEF text-black placeholder-gray w-full rounded-2xl py-3 focus:outline-none"
      />
    </div>
  );
}
