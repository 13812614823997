import React, { useEffect, useState } from 'react';

import { Button } from '../components/ui/button';
import { ArrowRight } from 'lucide-react';

import emailjs from 'emailjs-com';

const ContactForm = () => {
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    service: '',
    reason: ''
  });

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const submit = () => {
    //check if all fields are filled
    if (Object.values(formData).some(v => v.trim() === '')) {
      setIsError(true);

      return;
    }
    setIsError(false);

    sendEmail(formData);
  };
  useEffect(() => emailjs.init('DEo4LDCwqOcbGPig5'), []);

  const sendEmail = async data => {
    try {
      setLoading(true);
      setIsError(false);
      setIsSuccess(false);
      await emailjs.send('service_rw578vf', 'template_yho050m', {
        name: data.name,
        phone_number: data.phone,
        service: data.service,
        reason: data.reason
      });
      setLoading(false);
      setIsSuccess(true);
      setIsError(false);
      setFormData({
        name: '',
        phone: '',
        service: '',
        reason: ''
      });
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
      setLoading(false);
    }
  };

  return (
    <div className="grid sm:grid-cols-2  gap-x-4 gap-y-8">
      <div className="col-span-2 sm:col-span-1">
        <div className="B1 font-semibold pb-1">Name</div>
        <input
          onChange={handleChange}
          placeholder="Insert Your Full Name"
          name="name"
          value={formData.name}
          className="p-3 border rounded-lg w-full focus:outline-none"
        />
      </div>
      <div className="col-span-2 sm:col-span-1">
        <div className="B1 font-semibold pb-1">Phone Number</div>
        <input
          placeholder="Insert Your Phone Number"
          name="phone"
          value={formData.phone}
          type="number"
          onChange={handleChange}
          className="p-3 border rounded-lg w-full focus:outline-none"
        />
      </div>

      <div className="col-span-2">
        <div className="B1 font-semibold pb-1">Services</div>
        <select
          onChange={handleChange}
          name="service"
          value={formData.service}
          className=" p-3 rounded-lg border w-full focus:outline-none"
        >
          <option value="">Select Service</option>
          <option value="Service 1">Service 1</option>
        </select>
      </div>
      <div className="col-span-2">
        <div className="B1 font-semibold pb-1">Reason For Visit</div>
        <textarea
          onChange={handleChange}
          placeholder="Insert Your Message"
          name="reason"
          value={formData.reason}
          className="border rounded-lg w-full h-28 p-3 focus:outline-none"
        ></textarea>
      </div>
      <div className="w-1/2">
        <Button onClick={submit} disabled={loading}>
          {loading ? 'Loading ...' : 'Submit'}
          {loading ? '' : <ArrowRight className="ml-1 w-4 h-5" />}
        </Button>
      </div>
      {isError && <div className="text-xs text-red-500">All fields are required!</div>}
      {isSuccess && <div className="text-xs text-red-500">Successfully sent!</div>}
    </div>
  );
};

export default ContactForm;
