import React from 'react';
import Footer from '../navigation/Footer';
import MenuBar from '../navigation/Menu';
import { ReactComponent as Bone } from '../images/new/bone.svg';
import { ReactComponent as Arrow } from '../images/new/arrow.svg';
import TestService from './../images/new/test_service.png';

export default function Services() {
  const servicesArray = [
    { onlineConsultation: false, name: 'Physiotherapy' },
    { onlineConsultation: false, name: 'General Osteopathy' },
    { onlineConsultation: true, name: 'Logotherapy' },
    { onlineConsultation: true, name: 'Practical Biodecoding' },
    { onlineConsultation: true, name: 'Systemic Coaching for Individuals' },
    { onlineConsultation: true, name: 'Emotional Disorder Treatment' },
    { onlineConsultation: true, name: 'Nutrition Counselling' },
    { onlineConsultation: true, name: 'Chronic Pain Counselling' },
    { onlineConsultation: true, name: 'Onsite Consultation ' },
    { onlineConsultation: true, name: 'Home visits' },
    { onlineConsultation: true, name: 'Women’s Health' },
    { onlineConsultation: true, name: 'Breast Feeding Counselling' },
    { onlineConsultation: true, name: 'Group Workshops for Women' },
    { onlineConsultation: true, name: 'Logotherapy Tests' }
  ];
  return (
    <>
      <MenuBar />
      <div className="bg-services sm:mt-px sm:h-[448px] h-[248px] " style={{ backgroundSize: '100% 100%' }}>
        <div className=" mx-auto max-w-7xl  px-10 sm:px-0 w-full h-full flex items-center">
          <div>
            <div className="flex">
              <Bone />
              <div className="B1 font-semibold text-primary">HOW WE CAN HELP YOU</div>
            </div>
            <div className="D1 font-bold text-bold_secondary">Our Services</div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-10 sm:px-0 sm:pb-24 sm:pt-28 py-10">
        <div className="grid sm:grid-cols-4 grid-cols-1 gap-4 ">
          {servicesArray.map((service, index) => (
            <div style={{boxShadow: '0px 1px 7px 1px #00000026'
            }} className=" rounded-2xl mx-auto w-full" key={index}>

                <img src={TestService} className='w-full' />
                <div className="flex justify-between items-center px-4 py-6 w-full">
                  <div className='H4 font-bold text-bold_secondary'>{service.name}</div>
                  <div>
                  <Arrow />
                  </div>

              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
