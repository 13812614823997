import React from 'react';
import Footer from '../navigation/Footer';
import MenuBar from '../navigation/Menu';
import { ReactComponent as Bone } from '../images/new/bone.svg';
import { ReactComponent as Approch } from '../images/new/approch.svg';
import { ReactComponent as Message } from '../images/new/message.svg';
import { Carousel } from 'react-responsive-carousel';
import AboutUsImg from './../images/new/aboutUs.png';
import AboutUsImg3 from './../images/new/aboutUs-3.png';
import Team from './../images/new/team.png';

import { ReactComponent as Empathy } from '../images/new/empathy.svg';
import { ReactComponent as Collaboration } from '../images/new/collabotation.svg';
import { ReactComponent as Personalization } from '../images/new/personalization.svg';
import { ArrowLeft, ArrowRight } from 'lucide-react';

export default function AboutUs() {
  const slider = [
    {
      title: 'Empathy',
      logo: Empathy,
      description:
        'We approach each patient with empathy, understanding their unique needs and challenges.'
    },
    {
      title: 'Collaboration',
      logo: Collaboration,
      description:
        'working together, we provide a comprehensive and unified approach to healthcare, optimizing outcomes for our patients.'
    },
    {
      title: 'Personalization',
      logo: Personalization,
      description:
        'Personalized care ensures that our patients receive the right interventions at the right time, enhancing the overall effectiveness of their treatment.'
    }
  ];

  const [state, setState] = React.useState({
    currentSlide: 0,
    autoPlay: true,
});

  const next = () => {
    setState((prevState) => ({
        ...prevState,
        currentSlide: prevState.currentSlide + 1,
    }));
};

const prev = () => {
    setState((prevState) => ({
        ...prevState,
        currentSlide: prevState.currentSlide - 1,
    }));
};

const updateCurrentSlide = (index) => {
  const { currentSlide } = state;
  if (currentSlide !== index) {
      setState({
          ...state,
          currentSlide: index,
      });
  }
};

  return (
    <>
      <MenuBar />
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:py-20 py-10 max-w-7xl mx-auto sm:px-0 px-10">
        <img src={AboutUsImg} />
        <div className="sm:pl-16  flex items-center justify-center">
          <div className="justify-center items-center sm:pt-0 pt-10">
            <div className="flex">
              <Bone />
              <div className="B1 font-semibold text-primary">GET TO KNOW US</div>
            </div>
            <div className="D1 font-bold text-bold_secondary pb-6">About us</div>
            <div>
              Motion Emotion was launched in January 2021 by Toni Merhi and Dr. Maya Naddaf in order
              to provide a healthcare entity centered on the person and aiming to take care of the
              patient’s global wellbeing whether on the physical, emotional, social, or spiritual
              level. Today, we are a team of healthcare professionals collaborating together to
              provide you the ultimate holistic journey toward global healing & wellbeing.
            </div>
          </div>
        </div>
      </div>
      <div className="bg-aboutUs-2  mt-px sm:px-0 px-10 sm:h-[768px] h-[900px] sm:bg-cover bg-right">
        <div className="max-w-7xl h-full mx-auto">
          <div className="h-full sm:w-1/2   ml-auto sm:pl-16 flex items-center justify-center ">
            <div>
              <div className="D1 font-bold text-bold_secondary pb-4">
                Your wellness journey starts here!
              </div>
              <div className="B1">
                Whether you are seeking relief from physical pain, support for mental health
                concerns, or comprehensive medical care, Motion Emotion is your partner on the path
                to wellness. We invite you to explore the possibilities of a healthier, happier life
                with us. We look forward to accompanying you on your journey to optimal well-being.
              </div>
              <div className="flex pt-10">
                <div className="pr-6">
                  <Message />
                </div>
                <div>
                  <div className="H2 font-bold text-bold_secondary pb-2">Our Story</div>
                  <div className="B1">
                    Established with a vision to redefine healthcare, Motion Emotion was founded on
                    the principle that true well-being encompasses physical, mental, and medical
                    aspects. We saw a need for a healthcare destination where individuals could find
                    a seamless blend of expertise, compassion, & innovation.
                  </div>
                </div>
              </div>
              <hr className="my-8 border-bold_secondary" />
              <div className="flex">
                <div className="pr-6">
                  <Approch />
                </div>
                <div>
                  <div className="H2 font-bold text-bold_secondary pb-2">Our Approach</div>
                  <div className="B1">
                    What sets us apart is our commitment to a holistic approach to treatment. We go
                    beyond symptoms, aiming to understand the root causes of your health challenges.
                    By combining the latest advancements in physiotherapy, psychotherapy, and
                    medical care, we design personalized treatment plans that prioritize your
                    holistic well-being.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto  max-w-7xl py-10 sm:py-28 px-10 sm:px-0 ">
        <div className="grid  grid-cols-1  sm:grid-cols-3">
          <div className="sm:pt-6 sm:border-y-0 border-bold_secondary sm:border sm:my-6 mt-6 sm:border-l-0 sm:pr-8">
            <div className="H2 font-bold text-bold_secondary pb-2">Our Mission</div>
            <div className="B1">
              At Motion Emotion, our mission is to cultivate a healthier and happier community by
              delivering comprehensive and compassionate healthcare. We are dedicated to providing a
              holistic approach to treatment, integrating the expertise of diverse healthcare
              professionals. Our commitment is to empower individuals on their journey to optimal
              well-being through personalized and patient-centric care.
            </div>
          </div>
          <div className="sm:pt-6 sm:pl-8 my-6">
            <div className="H2 font-bold text-bold_secondary pb-2">Our Vision</div>
            <div className="B1">
              At Motion Emotion, we envision a future where every individual can access a seamless
              continuum of care, where physical, mental, and medical aspects are addressed
              collaboratively. Our aspiration is to create a community that thrives on holistic
              health, embracing life to the fullest potential.
            </div>
          </div>
          <img src={AboutUsImg3} className="sm:pl-8" />
        </div>
      </div>

      <div className="max-w-7xl sm:flex hidden mx-auto h-[550px]">
      <div className="text-bold_secondary D1 relative flex-shrink-0 font-bold w-[295px] ">
        <div>Our Values</div>  
        <div className='mt-44'>
        <button onClick={prev} className='rounded-full border border-bold_secondary mx-1' >
<ArrowLeft/>
                    </button>  
        <button onClick={next} className='rounded-full border border-bold_secondary mx-1' >
                        <ArrowRight/>
                    </button>
                    </div>     
          </div>
        
          <div>
        <div
          style={{
            background: 'linear-gradient(90deg, #A0CFCB 0%, #0E6C63 100%)'
          }}
          className="absolute rounded-2xl flex w-full h-1/4"
        >
          <div className='pl-10'>
          <Carousel
          selectedItem={state.currentSlide}
          onChange={updateCurrentSlide}
            centerSlidePercentage={10}
            showIndicators={false}
            centerMode={true}
            showStatus={false}
            showArrows={false}
            showThumbs={false}
            className="pt-16 "
          >
            {slider.map((record, index) => (
              <div key={index} className="p-2">
                <div
                  style={{
                    boxShadow: '0px 4px 4px 0px #00000026'
                  }}
                  className="  flex justify-center mx-2 items-center flex-col h-[374px] w-[295px] rounded-lg  bg-white"
                  key={index}
                >
                  <record.logo />
                  <div> {record.title}</div>
                  <div> {record.description}</div>
                </div>
              </div>
            ))}
          </Carousel>
        </div></div>
        </div>
      </div>
      <div className="mx-auto max-w-3xl px-10 sm:px-0">
        <div className="text-center pb-4 D1 font-bold text-bold_secondary">Our Team</div>
        <div className="text-center B1 pb-20">
          Our team is a diverse and dedicated group of professionals, including highly skilled
          physiotherapists, empathetic psychotherapists, and experienced medical doctors. Together,
          we collaborate seamlessly to address your unique health needs, recognizing that your
          journey to wellness is multifaceted.
        </div>
        <div className="grid sm:grid-cols-3 grid-cols-1 gap-8 pb-36">
          <div className="border border-bold_secondary rounded-xl">
            <img className="w-full" src={Team} />
          </div>
          <div className="border border-bold_secondary rounded-xl">
            <img className="w-full" src={Team} />
          </div>
          <div className="border border-bold_secondary rounded-xl">
            <img className="w-full" src={Team} />
          </div>

          <div className="border border-bold_secondary rounded-xl">
            <img className="w-full" src={Team} />
          </div>
          <div className="border border-bold_secondary rounded-xl">
            <img className="w-full" src={Team} />
          </div>
          <div className="border border-bold_secondary rounded-xl">
            <img className="w-full" src={Team} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
